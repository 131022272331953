import React, { useEffect, useRef, useState } from 'react';
import { Stack } from "admin-frontend";
import './prism';
window.Prism.manual = true;
// https://css-tricks.com/creating-an-editable-textarea-that-supports-syntax-highlighted-code/

export function CodeEditor({ label, error, language, multiline = 8, value, onChange, readOnly, placeholder }) {
  const refHighlighting = useRef(null),
    refHighlightingContent = useRef(null),
    refEditing = useRef(null)

  const [scroll, setScroll] = useState({
    scrollLeft: 0,
    scrollTop: 0
  })
  const className = `highlighting-content language-${language}`
  
  const [htmlCode] = useState(handleNewLines(htmlEscaping(value)));
  
  function syncScroll(e) {
    const highlighting = refHighlighting.current,
      editing = refEditing.current

    const scrollLeft = (e && e.scrollLeft) || editing.scrollLeft,
      scrollTop = (e && e.scrollTop) || editing.scrollTop

    highlighting.scrollLeft = scrollLeft
    highlighting.scrollTop = scrollTop
    editing.scrollLeft = scrollLeft
    editing.scrollTop = scrollTop

    return {
      scrollLeft: highlighting.scrollLeft, /* don't change */
      scrollTop: highlighting.scrollTop /* don't change */
    }
  }

  function handleKeyDown(event) {
    const editing = refEditing.current
    let value = editing.value

    if (event.key === "Tab") { /* tab key pressed */
      event.preventDefault()
      editing.lastInputIsTab = true

      const { selectionStart, selectionEnd } = editing;
      value = value.substr(0, selectionStart) + "\t" + value.substr(selectionEnd);
      editing.value = value;
      editing.selectionStart = selectionStart + 1;
      editing.selectionEnd = selectionEnd + 1;
      onChange(value)
    }
  }

  function handleNewLines(string) { 
    if (string.at(-1) === "\n")
      string += " "
    return string
  }

  useEffect(() => {
    if (readOnly) {
      syncScroll({
        scrollTop: 99999
      })
    }
  }, [value])

  useEffect(() => {
    const highlightingContent = refHighlightingContent.current;
    highlightingContent.textContent = value;
    window.Prism.highlightElement(highlightingContent);
    syncScroll()
  }, [value, language])

  useEffect(() => {
    syncScroll()
  }, [scroll])

  useEffect(() => {
    if (refEditing && refEditing.current) {
      const editing = refEditing.current,
        { set } = Object.getOwnPropertyDescriptor(HTMLTextAreaElement.prototype, "value"),
        events = ["focus", "click", "keydown", "input", "keyup"]

      let lastPosition = {
        selectionStart: editing.selectionStart,
        selectionEnd: editing.selectionEnd
      }

      if (readOnly) { // restore to "original"
        Object.defineProperty(editing, "value", {
          set(newVal) {
            set.call(this, newVal)
            return newVal
          }
        })
      } else {
        events.forEach(eventName => {
          editing.addEventListener(eventName, watcherFn)
        })
      }

      function watcherFn() {
        const { selectionStart, selectionEnd } = editing

        lastPosition = {
          selectionStart,
          selectionEnd
        }

        Object.defineProperty(editing, "value", {
          set(newVal) {
            set.call(this, newVal)

            if (editing.lastInputIsTab) {
              // where cursor moves after tab - moving forward by 1 char to after tab
              editing.lastInputIsTab = false
              lastPosition.selectionStart = lastPosition.selectionStart + 1
              lastPosition.selectionEnd = lastPosition.selectionStart
            } else {
              editing.selectionStart = lastPosition.selectionStart
              editing.selectionEnd = lastPosition.selectionEnd
            }
            return newVal
          }
        })
      }

      return () => {
        if (!readOnly) {
          events.forEach(eventName => {
            editing.removeEventListener(eventName, watcherFn)
          })
        }
      }
    }
  }, [refEditing])

  return (
    <Stack className='modd-text-field-container' spacing="extraTight" vertical>
      {label && <label className='modd-label modd-text-field-label'>{label}</label>}
      <div className={`code-input${error && ' modd-error' || ''}`} style={{ height: (multiline * 25) + "px" }}>
        <div className={`code-input-read-only` + (readOnly ? " active" : "")}></div>
        <pre className="highlighting" ref={refHighlighting} aria-hidden="true">
          <code className={className} ref={refHighlightingContent} dangerouslySetInnerHTML={{ __html: htmlCode }}></code>
        </pre>
        <textarea className="editing " ref={refEditing} onScroll={() => {
          setScroll(syncScroll());
        }} onInput={() => { 
          onChange(refEditing.current.value)
          setScroll();
        }} onKeyDown={handleKeyDown} spellCheck="false" placeholder={placeholder} value={value}></textarea>
      </div>
      {error && error !== true && <div className='modd-error-text'>{error}</div>}
    </Stack>
  );
}


function htmlEscaping(string) {
  return string.replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#39;")
}
