import {
  Banner,
  Spinner,
  Button,
  Popover,
  Card,
  FormLayout,
  ActionList,
  Select,
  Checkbox,
  TextField,
  Modal,
  Thumbnail,
  ChoiceList,
  EmptyState,
  TextContainer,
  Icon
} from 'admin-frontend';
import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { useSpotFetch } from "../useSpotFetch";
import { Stack } from "admin-frontend";
import { useSpotAPI, useSpot, SpotState, useSpotCPSDK } from "../components/API";
import { CachedTextField, SearchField } from "../components/Fields";
import { Auth, MultiTable, useRedirect, useCreateModal } from "admin-frontend";
import { LocaleSwitcher } from "../components/Localization"
import { DeleteMajor, PinMajor, PlusMinor, ArrowDownMinor, ImageMajor } from 'admin-frontend';

import { Grid, rearrange } from "../components/Grid";
import { BannerTile  } from "../Tabs/Banners";

export function DefaultImage({ width, height }) {
  return (<svg
    draggable="false"
    className="placeholder--image"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 525.5 525.5"
    width={width || 256}
    height={height || 256}
  >
    <path d="M324.5 212.7H203c-1.6 0-2.8 1.3-2.8 2.8V308c0 1.6 1.3 2.8 2.8 2.8h121.6c1.6 0 2.8-1.3 2.8-2.8v-92.5c0-1.6-1.3-2.8-2.9-2.8zm1.1 95.3c0 .6-.5 1.1-1.1 1.1H203c-.6 0-1.1-.5-1.1-1.1v-92.5c0-.6.5-1.1 1.1-1.1h121.6c.6 0 1.1.5 1.1 1.1V308z"></path>
    <path d="M210.4 299.5H240v.1s.1 0 .2-.1h75.2v-76.2h-105v76.2zm1.8-7.2l20-20c1.6-1.6 3.8-2.5 6.1-2.5s4.5.9 6.1 2.5l1.5 1.5 16.8 16.8c-12.9 3.3-20.7 6.3-22.8 7.2h-27.7v-5.5zm101.5-10.1c-20.1 1.7-36.7 4.8-49.1 7.9l-16.9-16.9 26.3-26.3c1.6-1.6 3.8-2.5 6.1-2.5s4.5.9 6.1 2.5l27.5 27.5v7.8zm-68.9 15.5c9.7-3.5 33.9-10.9 68.9-13.8v13.8h-68.9zm68.9-72.7v46.8l-26.2-26.2c-1.9-1.9-4.5-3-7.3-3s-5.4 1.1-7.3 3l-26.3 26.3-.9-.9c-1.9-1.9-4.5-3-7.3-3s-5.4 1.1-7.3 3l-18.8 18.8V225h101.4z"></path>
    <path d="M232.8 254c4.6 0 8.3-3.7 8.3-8.3s-3.7-8.3-8.3-8.3-8.3 3.7-8.3 8.3 3.7 8.3 8.3 8.3zm0-14.9c3.6 0 6.6 2.9 6.6 6.6s-2.9 6.6-6.6 6.6-6.6-2.9-6.6-6.6 3-6.6 6.6-6.6z"></path>
  </svg>)
}

export function ProductBannerTile({ authFetch, swatchHash, bannerHash, banner, disabled, onDelete, onChangeIndex, innerRef, idx, ...props }) {
  return (<div ref={innerRef} {...props} className={`search-preview-product ${idx != null ? `search-preview-product-${idx}` : ""}`}>
    {onDelete && <Button key="search-preview-delete" plain disabled={disabled} square className="search-preview-delete" onClick={() => onDelete(banner)}><Icon source={DeleteMajor} color="base" /></Button>}
    {onChangeIndex && <CachedTextField disabled={disabled} onChange={(val, type, event) => onChangeIndex(parseInt(val) - 1, type, event)} className="search-preview-pinned-ordinal" type='text' value={idx+1}/>}
    <BannerTile onDelete={onDelete} key={"banner-" + banner.banner_id} authFetch={authFetch} swatches={swatchHash} banner={bannerHash && bannerHash[banner.banner_id]} idx={idx}/>
  </div>);
}

export function ProductTile({ product, info, disabled, onDelete, onUnpin, onPin, onUnantipin, image, onImage, onHoverImage, onChangeIndex, onSelect, idx, selected, innerRef, merchandisingActions, ...props }) {
  const [popoverActive, setPopoverActive] = useState(false);
  const [hoverImageId, setHoverImageId] = useState(null);
  const merchandisingActionsRef = useRef(null);
  const spotAPI = useSpotAPI();
  const [spotCPSDK, refreshRequest] = useSpotCPSDK();
  const defaultFeaturedImage = spotAPI.getProductImage(product);
  const split_id = (Array.isArray(product)) ? product[0].split_id : product.split_id;

  const featuredImage = spotAPI.getProductImage(product, null, (hoverImageId || image) ? { [split_id]: (hoverImageId || image) } : null);

  let thumbnail = featuredImage ? (
    <Thumbnail
      draggable="false"
      alt={spotAPI.getProductTitle(product)}
      source={spotAPI.getSizedImage(
        featuredImage,
        "256x256"
      )}
    />
  ) : (
    <DefaultImage width={256} height={256}/>
  );
  const spot = useSpot();
  const badges = spot && spot.spotDOM.getBadges(product);

  const imageSelector = onImage && (<Button plain disabled={disabled} square onClick={() => { 
    setPopoverActive(!popoverActive) 
    product.images.forEach((image) => { var element = new Image(); element.src = spotAPI.getSizedImage(image, "256x256"); });
  }}><Icon source={ImageMajor} color={image && image != defaultFeaturedImage ? "interactive" : "base"} /></Button>);
  const imageList = onImage && (<Popover
      preferredAlignment="right"
      className="search-preview-setimage" 
      active={popoverActive}
      activator={imageSelector}
      autofocusTarget="first-node"
      onClose={() => { setPopoverActive(false); setHoverImageId(null); }}
    >
      <ActionList
        actionRole="menuitem"
        items={(Array.isArray(product) ? product : [product]).flatMap((product) => product.images.map((i) => { return {
          onMouseEnter: () => { 
            setHoverImageId(i.id); 
          },
          content: (i.alt || i.id),
          onAction: () => { onImage(i != defaultFeaturedImage ? i.id : null); setPopoverActive(false); }
        }}))}
      />
  </Popover>);

  useEffect(() => {
    if (spotCPSDK && merchandisingActionsRef.current && product && merchandisingActions) {
      if (!merchandisingActionsRef.current.merchandisingActionElements)
        merchandisingActionsRef.current.merchandisingActionElements = [];
      merchandisingActions.forEach((m, idx) => {
        if (merchandisingActionsRef.current.merchandisingActionElements[idx])
          merchandisingActionsRef.current.merchandisingActionElements[idx].remove();
        try {
          merchandisingActionsRef.current.merchandisingActionElements[idx] = m.on_render(product, spotCPSDK);
        } catch (e) {
          console.error(`Error in merchandising action '${m.name}': ${e}`);
        }
        if (merchandisingActionsRef.current.merchandisingActionElements[idx])
          merchandisingActionsRef.current.appendChild(merchandisingActionsRef.current.merchandisingActionElements[idx]);
      });
    }
  }, [merchandisingActions, spotCPSDK, refreshRequest]);
    
  const element = (<div ref={innerRef} {...props} className={`search-preview-product ${idx != null ? `search-preview-product-${idx}` : ""}`}>
    {badges && badges.length > 0 && <div className='search-preview-badges'>
      {badges && badges.map((b) => <div key={"badge-" + b.id} style={b.swatch ? (b.swatch.color ? { backgroundColor: "#" + b.swatch.color } : { backgroundImage: b.swatch.image }) : {}} className={`search-preview-badge${b.position ? ` search-preview-badge-position-${b.position}` : ""}`}>{b.name}</div>)}
    </div>}
    {onDelete && <Button key="search-preview-delete" plain disabled={disabled} square className="search-preview-delete" onClick={() => onDelete(product)}><Icon source={DeleteMajor} color="base" /></Button>}
    {onImage && imageList}
    {onChangeIndex && <CachedTextField disabled={disabled} onChange={(val, type, event) => onChangeIndex(parseInt(val) - 1, type, event)} className="search-preview-pinned-ordinal" type='text' value={idx+1}/>}
    {onPin && <Button plain disabled={disabled} square className="search-preview-pinned" onClick={() => onPin(product)}><Icon source={PinMajor} /></Button>}
    {onUnpin && <Button plain disabled={disabled} square primary className="search-preview-pinned" onClick={() => onUnpin(product)}><Icon source={PinMajor} /></Button>}
    {onUnantipin && <Button plain disabled={disabled} square className="search-preview-antipinned" onClick={() => onUnantipin(product)}><Icon source={ArrowDownMinor} color="critical" /></Button>}
    {onSelect && <div className="search-preview-select"><Checkbox disabled={disabled} onChange={(value) => onSelect(value)} checked={selected}/></div>}
    <span className="search-preview-image">{thumbnail}</span>
    <span className="search-preview-title">{spotAPI.getProductTitle(product)}</span>
    <span className="search-preview-price">{spotAPI.formatMoney(spotAPI.getProductPrice(product), "{{ amount }}")}</span>
    {info && <div className="search-preview-info">{info}</div>}
    {merchandisingActions && merchandisingActions.length > 0 && <div ref={merchandisingActionsRef} className='search-preview-merchandising-actions'></div>}
  </div>)


  return element;
}

export function ProductList({ products, onRowClick }) {
  const redirect = useRedirect();
  const spotAPI = useSpotAPI();
  return (<MultiTable
    headings={["Image", "Product"]}
    rows={products.map((p) => ([(spotAPI.getProductImage(p) ? (<img alt={p.title} src={spotAPI.getSizedImage(spotAPI.getProductImage(p), "64x64")}/>) : <DefaultImage width={64} height={64}/>), p.title]))}
    onRowClick={onRowClick != null ? onRowClick : ((row, idx) => redirect('admin:/products/' + products[idx].id)) }
  />);
}

export function ProductCarousel({ products, badges }) {
  return (<div style={{display: "grid", width: "100%", gridTemplateColumns: "repeat(" + Math.min(products.length, 10) + ", 1fr)"}}>
    {products.map((product) => <ProductTile badges={badges} product={product}/>)}
  </div>);
}

export function ProductGrid({ products, badges, columns }) {
  return (<div style={{display: "grid", gridTemplateColumns: "repeat(" + columns + ", 1fr)"}}>
    {products.map((product) => (<ProductTile badges={badges}  product={product}/>))}
  </div>);
}


function formatScore(score) {
  return parseFloat(score).toFixed(2);
}

export function ProductSearchExplanationPopover({ explanation }) {
  if (!explanation || !explanation.query)
    return null;
  const productWeight = explanation.weight;
  return (
    <table>
      <tbody>
        <tr>
          <td colspan={2}><b>Relevancy Score</b></td>
          <td>&nbsp;</td>
          <td>&nbsp;=</td>
          <td>&nbsp;</td>
          <th>{formatScore(explanation.relevancy)}</th>
        </tr>
        <tr>
          <td colspan={6}><hr/></td>
        </tr>
        <tr>
          <td colspan={2}>weight total</td>
          <td>&nbsp;</td>
          <td>&nbsp;*</td>
          <td>&nbsp;</td>
          <td>{formatScore(productWeight)}</td>
        </tr>
        <tr>
          <td colspan={2}>word total</td>
          <td>&nbsp;</td>
          <td>&nbsp;+</td>
          <td>&nbsp;</td>
          <td>{formatScore(explanation.query.map((word) => word.relevancy / productWeight).reduce((a,b) => a+b, 0))}</td>
        </tr>
        {/*<tr>
          <th>Word</th><th>Field</th><th>Type</th><th>Op</th><th>Value</th><th>Total</th>
        </tr>*/}
        {explanation.query.flatMap((word) => [
          word.targets.flatMap((target) => [
          (<tr>
            <td colspan={6}><hr/></td>
          </tr>), (<tr>
            <td colspan={2}>{target.target}</td>
            <td>{target.transform == "none" ? "exact" : target.transform}</td>
            <td>⌊*⌋</td>
            <td>{formatScore(target.weight / productWeight)}</td>
            <td>{formatScore(target.relevancy / productWeight)}</td>
          </tr>),
          target.hits.flatMap((hit) => [(<tr>
            <td>&nbsp;</td>
            <td>{hit.field}</td>
            <td>{hit.type}</td>
            <td>&nbsp;+</td>
            <td>{formatScore(hit.weight / productWeight)}</td>
            <td>&nbsp;</td>
          </tr>)
          ])
          ])
        ])}
        <tr>
          <td colspan={6}><hr/></td>
        </tr>
        <tr>
          <th colspan={6}>weight</th>
        </tr>
        {explanation.weights.flatMap((weight) => [
          (<tr>
            <td>{weight.field}</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;+</td>
            <td>{formatScore(weight.value)}</td>
            <td>{formatScore(weight.value * weight.weight)}</td>
          </tr>),
          ...(weight.weight != 1.0 ? [
            <tr>
              <td>{weight.field} weight</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;*</td>
              <td>{formatScore(weight.weight)}</td>
              <td>&nbsp;</td>
            </tr>
          ] : [])
          ])}
        </tbody>
    </table>
  );
}

export function ProductSearchResult({ idx, profile, product, color_mapping, setPopover, popover, query, authFetch, innerRef, disabled, ...props }) {
  const json = query && JSON.parse(query);
  if (!json || !popover || !json.attributes || !json.attributes.search || json.attributes.search.query == '')
    return <ProductTile disabled={disabled} idx={idx} innerRef={innerRef} {...props}  product={product}/>;
  const activator = (<Button ref={innerRef}  {...props} fullWidth onClick={() => {
    setPopover({ product_id: product.id, explanation: null });
    authFetch("/api/search/explain", { method: "POST", query: { id: product.id }, body: query }).then((r) => {
      setPopover({ product_id: product.id, explanation: r });
    })
  }} key={product.id + "-" + product.variants[0].id}>
    <ProductTile disabled={disabled} idx={idx} innerRef={innerRef} {...props} product={product}/>
  </Button>);
  if (!popover || popover.product_id != product.id)
    return activator;

  return (<div ref={innerRef} {...props} style={{height: '420px'}}>
    <Popover
      active={popover !== null}
      activator={activator}
      preferredAlignment="right"
      preferredPosition="above"
      fixed={true}
      fluidContent={true}
      fullHeight={true}
      onClose={() => { setPopover(null); }}
      sectioned
    >
      {!popover.explanation && <Spinner size="large"/>}
      {popover.explanation && <ProductSearchExplanationPopover explanation={popover.explanation}/>}
    </Popover>
  </div>);
}

export function ProductSearch({ className, paginationType = "select", resultsPerPage, controls, splits, merges, merchandisingActions, preservePosition = false, beforeQueryRun, title, onDragDrop, onDelete, renderProduct, renderBanner, setProducts, products, bannerHash, banners, setBanners, swatchHash, selectedColumns, setSelectedColumns, onSelect, selection, columnChoices, setColumnChoices, sidebarExtra, onBeginQuery, onEndQuery, disabled, selectedCallback }) {
  const [profile] = Auth.useProfile();
  const [popover, setPopover] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isQuerying, setIsQuerying] = useState(false);
  const [query, setQuery] = useState(null);
  const [isSnapping, setIsSnapping] = useState(true);
  const snappingTimeout = useRef(null);

  const [initialSpotLoad, setInitialSpotLoad] = useState(false);
  const [columnSelectorOpen, setColumnSelectorOpen] = useState(false);
  const authFetch = useSpotFetch();

  const [spotPane, setSpotPane] = useState(null);
  const [spotPaginationHelper, setSpotPaginationHelper] = useState(null);

  const cachedTiles = products ? products.flatMap((p, idx) => {
    return [...(banners || []).filter((b) => b.position == idx), p];
  }) : [];

  const spot = useSpot();

  const isLoadingOrQuerying = isLoading || isQuerying;

  useEffect(() => {
    return spotPaginationHelper && (() => {
      spotPaginationHelper.detach();
    });
  }, [spotPaginationHelper]);


  useEffect(() => {
    if (spot) {
      const firstRun = spot.spotDOM.productListener == null;
      if (!firstRun)
        spot.spotDOM.removeFieldListener(spot.spotDOM, 'products', spot.spotDOM.productListener);
      spot.spotDOM.productListener = ((newProducts) => {
        if (paginationType == "infinite" && spot.spotDOM.page() > 1) {
          setProducts([...(products || []), ...newProducts]);
        } else {
          setProducts(newProducts);
        }
        setIsLoading(false);
      });
      spot.spotDOM.products(spot.spotDOM.productListener, firstRun && spot.spotDOM.products() != null);
    }
  }, [products, setProducts, setIsLoading, spot]);

  useEffect(() => {
    if (spot) {
      var transforms = spot.spotDOM.queryTransforms()
      transforms.forEach((t) => spot.spotDOM.removeQueryTransform(t));
      if (beforeQueryRun) {
        spot.spotDOM.addQueryTransform([(q) => {
          return beforeQueryRun(q, spot.spotDOM);
        }]);
      }
    }
  }, [beforeQueryRun, spot]);

  useEffect(() => {
    if (spot) {
      spot.spotDefault.pane = new spot.spotDefault.Pane({ classes: ["swatches"] });
      setSpotPane(spot.spotDefault.pane);
      spot.spotDefault.scrollPaginationHelper = paginationType == "infinite" ? new spot.spotDefault.ScrollPaginationHelper({ topOfPage: (() => (document.querySelector('.search-preview') ? document.querySelector('.search-preview').getBoundingClientRect().top : 100) + window.scrollY), preservePosition: preservePosition }).attach() : null;
      setSpotPaginationHelper(spot.spotDefault.scrollPaginationHelper);
      spot.spotDOM.beginQuery(() => {
        setIsQuerying(isQuerying);
        setIsSnapping(true);
        var newQuery = spot.spotDOM.generateQuery()
        setQuery(JSON.stringify({ query: newQuery.innerQuery, attributes: newQuery.innerAttributes }));
        spot.spotDefault.pane.paneElement.querySelectorAll('input, select').forEach((i) => i.disabled = true);
      }).endQuery(() => {
        setIsQuerying(false);
        if (snappingTimeout.current)
          clearTimeout(snappingTimeout.current);
        snappingTimeout.current = setTimeout(() => { setIsSnapping(false); }, 100);
        spot.spotDefault.pane.paneElement.querySelectorAll('input, select').forEach((i) => i.disabled = false);
      });
    }
  }, [spot]);

  useEffect(() => {
    if (spotPane)
      document.querySelector(".search-preview-panel").appendChild(spotPane.paneElement);
  }, [spotPane]);

  let pageListing = Array(
    Math.max(1, Math.ceil((spot.spotDOM.count() || 0) / spot.spotDOM.paginate()))
  ).fill(1).map(function (e, i) {
    return { label: i + 1, value: "" + (i + 1) };
  });

  const isUnmodified = !spot.spotDOM.search() && spot.spotDOM && spot.spotDOM.facets().filter((facet) => facet.isEnabled()).length == 0;
  const isDraggable = onDragDrop && isUnmodified;
  const showNoResults = isUnmodified && cachedTiles.length === 0 && !isLoadingOrQuerying;

  const itemsPerRow = 4;
  const rowHeight = 428;
  const selectionHash = selection && Object.fromEntries(selection.map((id) => [id, true]));

  const columnActivator = columnChoices && columnChoices.length > 0 && (<Button disclosure={columnSelectorOpen ? "up" : "down"} onClick={() => { setColumnSelectorOpen(!columnSelectorOpen); }}>Merchandiser Info</Button>);

  const [customColumn, setCustomColumn] = useState({
    value: "",
    label: "",
    renderText: "(product) => { return (Array.isArray(product) ? product : [product])[0].id; }",
    show: false,
    error: null
  });
  
  useEffect(() => {
    let error = null;
    try {
      const func = eval(customColumn.renderText);
      if (typeof(func) != "function")
        error = "Invalid return type of column."
    } catch (e) {
      error = "" + e;
    }
    if (customColumn.error != error)
      setCustomColumn({ ...customColumn, error: error });
  }, [customColumn]);

  const customColumnActivator = (<Button primary onClick={() => { setCustomColumn({ ...customColumn, show: true }) }} fullWidth>+ Custom Column</Button>);
  const customColumnModal = useMemo(() => (<div>
    <Modal
      activator={customColumnActivator}
      open={customColumn.show}
      onClose={() => {
        setCustomColumn({ value: "", label: "", renderText: "(product) => { return (Array.isArray(product) ? product : [product])[0].id;  }", error: null, show: false });
      }}
      title="Add a Custom Column"
      primaryAction={{
        content: "Add",
        disabled: (!customColumn || customColumn.error || customColumn.name == "" || customColumn.value == "" || columnChoices.filter((c) => c.value == customColumn.value).length > 0),
        onAction: () => {
          if (customColumn.name != "" && customColumn.value != "") {
            setColumnChoices([...columnChoices, { ...customColumn, custom: true }]);
            setSelectedColumns([...selectedColumns, customColumn.value]);
            setCustomColumn({ value: "", label: "", renderText: "(product) => { return (Array.isArray(product) ? product : [product])[0].id;  }", error: null, show: false });
          }
        }
      }}
      secondaryActions={[{ content: "Close", onAction: () => { setCustomColumn({ value: "", label: "", renderText: "(product) => { return (Array.isArray(product) ? product : [product])[0].id; }", error: null, show: false }); } }]}
    >
      <Modal.Section>
        <TextContainer>
          <TextField placeholder="Enter Unique Label" label="Label" value={customColumn.label} onChange={(value) => {
            setCustomColumn({ ...customColumn, label: value });
          }}/>
          <TextField error={columnChoices && columnChoices.filter((c) => c.value == customColumn.value).length > 0 ? "Please enter a unique value." : null} label="Value" placeholder="Enter Unique Value" value={customColumn.value} onChange={(value) => {
            setCustomColumn({ ...customColumn, value: value });
          }}/>
          <TextField multiline={4} label="Code" error={customColumn.error} value={customColumn.renderText} onChange={(value) => {
            setCustomColumn({ ...customColumn, renderText: value });
          }}/>
        </TextContainer>
      </Modal.Section>
    </Modal>
  </div>), [customColumn]);


  useEffect(() => {
    if (spot && spot.spotDefault.pane)
      spot.spotDefault.pane.paneElement.querySelectorAll('input, select, button').forEach((e) => e.disabled = disabled);
  }, [disabled]);

  return (
    <div className={className}>
      <Card title={title} actions={[
        ...(setBanners ? [{
          content: <Button primary onClick={() => { setBanners([...banners, { text: "Banner", position: 0 }]); }}>Add Banner</Button>
        }] : []),
        ...(columnChoices && columnChoices.length > 0 ? [{ content: (<Popover onClose={() => { if (!customColumn.show) setColumnSelectorOpen(false); }} activator={columnActivator} active={columnSelectorOpen}>
        <div style={{ padding: "12px" }}>
          <ChoiceList allowMultiple selected={selectedColumns} choices={columnChoices.map((choice, idx1) => {
              return { label: choice.custom ? (<span>{choice.label} <Button onClick={() => {
                setColumnChoices(columnChoices.filter((c, idx2) => idx2 != idx1));
                setSelectedColumns(selectedColumns.filter((v) => v != choice.value));
              }} plain>&times;</Button></span>) : choice.label, value: choice.value };
            })} onChange={setSelectedColumns}/>
            {customColumnModal}
          </div>
        </Popover>)}] : []),
        { content: (<LocaleSwitcher locale={spot.spotDOM.locale()} setLocale={(l) => { if (l && spot.spotDOM.locale() != l.code) spot.spotDOM.locale(l.code); }} noAllLanguages={true}/>) }
      ]}>

        {controls && (<Card.Section>
          <FormLayout>
            <FormLayout.Group condensed>
              {(controls == true || controls.search == true) && <SearchField value={spot.spotDOM.search()} onChange={(val) => { spot.spotDOM.search(val); }} label={spot.spotDOM.collection() ? "Search Context in Collection" : "Search"} />}
              {(controls == true || controls.split || controls.merge) && <Select
                value={spot.spotDOM.merge() && spot.spotDOM.merge() != "none" ? ("merge-" + spot.spotDOM.merge()) : ("split-" + spot.spotDOM.split())}
                onChange={(val) => {
                  const groups = /^(\w+)\-(\w+)$/.exec(val);
                  if (groups) {
                    if (groups[1] == "split") {
                      spot.spotDOM.queryGuard(() => {
                        spot.spotDefault.rule({ merge_id: null });
                        spot.spotDOM.split(groups[2]);
                        spot.spotDOM.merge("none");
                      });
                    } else {
                      spot.spotDOM.queryGuard(() => {
                        spot.spotDefault.rule({ merge_id: groups[2] });
                        spot.spotDOM.split("none");
                        spot.spotDOM.merge(groups[2]);
                      });
                    }
                  }
                }}
                options={[{ label: "Auto", value: "split-auto" }, { label: "None", value: "split-none" }]
                  .concat(splits ? Object.keys(splits).map((s) => { return { label: s + " (Split)", value: "split-" + s }; }) : [])
                  .concat(merges ? Object.keys(merges).map((s) => { return { label: s + " (Merge)", value: "merge-" + s }; }) : [])
                }
                label="Split / Merge"
              />}
              {(controls == true || controls.results || controls.results) && <CachedTextField
                value={"" + spot.spotDOM.paginate()}
                onChange={(val) => { spot.spotDOM.paginate(val); }}
                inputMode="numeric"
                type="number"
                min="1"
                max="250"
                label="Results"
              />}
              {(controls == true || controls.page) && <Select
                value={"" + spot.spotDOM.page()}
                onChange={(val) => { spot.spotDOM.page(parseInt(val) || 1) }}
                options={pageListing}
                label="Page"
              />}
              {(controls == true || controls.sort) && <Select
                value={spot.spotDOM.sort() ? spot.spotDOM.sort().getUniqueId() : "auto"}
                onChange={(val) => { spot.spotDOM.sort(val); }}
                options={[
                  ...(!spot.spotDOM.collection() ? [{ label: "Auto", value: "auto" }] : []),
                  ...spot.spotDOM.sortOrders().map((so) => {
                    return {
                      label: so.getName(),
                      value: so.getUniqueId()
                    };
                  }),
                ]}
                label="Sort"
              />}
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>)}
        <Card.Section>
          <div
            className={"search-preview" + (showNoResults ? " no-results" : "") + (isSnapping ? " snapping" : "") + (isLoadingOrQuerying ? " loading" : "") + (initialSpotLoad ? " initial-loading" : "")}
            style={!showNoResults ? {
              display: "grid",
              gridTemplateColumns: "250px calc(100% - 250px)",
              gridTemplateRows: "auto",
            } : {}}
          >
            <div className="search-preview-sidebar">
              {sidebarExtra}
              <div
                className="search-preview-results"
              >
                {spot.spotDOM.count()} Products {isQuerying && <Spinner size="small"/>}
              </div>
              <div
                className="search-preview-panel"
              ></div>
            </div>
            {cachedTiles.length === 0 && isLoadingOrQuerying && (
              <Stack alignment="center" distribution="center">
                <Spinner size="large" />
              </Stack>
            )}
            {showNoResults && (<EmptyState heading={`No products found in this context.`}/>)}
            {(cachedTiles.length > 0 || !isLoadingOrQuerying) && (
              <Grid onDragDrop={isDraggable && onDragDrop} className={"search-preview-grid" + (isSnapping ? " snapping" : "")} isLoading={isLoadingOrQuerying} itemsPerRow={itemsPerRow} cellHeight={rowHeight} selectedCallback={selectedCallback}>
                {cachedTiles.map((e, idx) => {
                  if (e.banner_id !== undefined)
                    return renderBanner ? renderBanner(e, idx, selectedColumns, spot) : (<ProductBannerTile onDelete={onDelete} key={"banner-" + e.banner_id} authFetch={authFetch} swatches={swatchHash} bannerHash={bannerHash} banner={e} idx={idx}/>);
                  return renderProduct ? renderProduct(e, idx, selectedColumns, spot) : (<ProductSearchResult
                    onDelete={onDelete}
                    idx={idx}
                    disabled={disabled || isQuerying}
                    authFetch={authFetch}
                    setPopover={setPopover}
                    merchandisingActions={merchandisingActions}
                    popover={popover}
                    onSelect={onSelect && (() => { onSelect([...selection, e.id]); })}
                    selected={selectionHash && selectionHash[e.id]}
                    query={query}
                    key={Array.isArray(e) ? e[0].split_id : e.split_id}
                    profile={profile}
                    product={e}
                  />)})}
              </Grid>)}
          </div>
        </Card.Section>

      </Card>
    </div>
  );
}

function ProductContextualLogic({ children, ...props }) {
  const [settings, setSettings] = useState(null);
  const [products, setProducts] = useState([]);
  const spot = useSpot();
  const authFetch = useSpotFetch();

  useEffect(() => {
    if (!settings)
      authFetch("/api/setup/frontend_settings").then((r) => { setSettings(r); });
  }, [settings, authFetch]);

  useEffect(() => {
    if (spot && settings) {
      spot.spotDefault.setupObjects(settings);
      spot.spotDOM.query(function() { spot.spotDOM.updateQueryString({ merge: spot.spotDOM.merge() != "none" ? spot.spotDOM.merge() : null }); });
      spot.spotDOM.queryGuard(() => {
        spot.spotDOM.parseQueryString();
        spot.spotDefault.setupActiveRules();
        spot.spotDefault.setupQueryOnChange();
        var vars = spot.spotDOM.getUrlVars();
        if (vars['merge']) {
          spot.spotDefault.rule({ merge_id: vars['merge'] });
          spot.spotDOM.merge(vars['merge']);
        }
        spot.spotDOM.query();
      });
    }
  }, [spot, settings]);

  return (<ProductSearch splits={settings?.splits} merges={settings?.merges} products={products} setProducts={setProducts} {...props}>{children}</ProductSearch>);
}

export function ProductContextualSearch({ children, externalSearch, ...props }) {
  return (<SpotState externalSearch={externalSearch}>
    <ProductContextualLogic {...props}>{children}</ProductContextualLogic>
  </SpotState>);
}
