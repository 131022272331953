import React, { createContext, useContext, useEffect, useState, useMemo, useCallback } from "react";

const ACCOUNT_URL = "/account";
const Auth = createContext({});

const updateUrlParams = (url = '', params = {}) => {
  const urlObject = new URL(url, window.location);
  Object.entries(params).forEach(([key, value]) => {
    if (value instanceof Array) {
      value.forEach((element) => urlObject.searchParams.append(`${key}[]`, element));
    } else {
      urlObject.searchParams.append(key, value);
    }
  });
  return urlObject.toString();
};

export const AuthProvider = ({ apiKey, shop, embedded, urlToken, children }) => {
  const [profile, setProfile] = useState(null);
  const [profileError, setProfileError] = useState(null);
  
  const fetch = useCallback(async (url, options = {}, ...rest) => {
    const token = (urlToken || (window.shopify && await window.shopify.idToken()));
    return window.fetch(
      typeof options.query === 'object' ? updateUrlParams(url, options.query) : url,
      {
        ...(options.json ? { "method": "POST", body: JSON.stringify(options.json) } : {}),
        ...options,
        headers: {
          ...(token ? { "Authorization": `Bearer ${token}` } : {}),
          ...(options.json ? { "Content-Type": "application/json" } : {}),
          ...(options.headers || {}),
        },
      },
      ...rest
    ).then((r) => r.json()).then((response) => {
      if (!response.error)
        return response;
      if (response.error === 'no_subscription' && !window.location.pathname.match(ACCOUNT_URL)) {
        window.location = `${ACCOUNT_URL}?shop=${shop}${embedded && '&embedded=1' || ''}`;
      } else if (response.error === 'forbidden' || response.error === 'unauthorized') {
        window.location = `/auth?shop=${shop}${embedded && '&embedded=1' || ''}`;
      }
      return Promise.reject(response.error);
    });
  }, [urlToken, shop]);
  useEffect(() => {
    window.controlPanelFetch = fetch;
  }, [fetch]);
  
  const fetchSessionToken = useCallback(() => Promise.resolve(urlToken || (window.shopify && window.shopify.idToken())), [urlToken]);

  // App Bridge will force a reload on the page
  const waitingForReload = shop && embedded && window.self === window.top;

  const params = new URLSearchParams(window.location.search);
  const shouldUserAuthenticate = (params.has("hmac") && params.has("timestamp") && window.self === window.top) || (shop && !profile?.user?.watchtower && profile === false);
  const shouldRedirectToAccount = profile && (!profile.shop.subscriptions || profile.shop.subscriptions.length == 0) && !window.location.pathname.match(ACCOUNT_URL);
  
  useEffect(() => {
    if (embedded && !shop)
      window.location = `//${shop}/admin/apps/${apiKey}`;
  }, [embedded, shop, apiKey]);
  
  useEffect(() => {
    if (shouldUserAuthenticate) {
      alert("Unable to authenticate. Please contact support at support@moddapps.com.")
    } else if (shouldRedirectToAccount) {
      window.location = `${ACCOUNT_URL}?shop=${shop}${embedded && '&embedded=1' || ''}`;
    } else if (profile?.shop?.scope_dirty) {
      window.location = `/auth/scope?shop=${shop}${embedded && '&embedded=1' || ''}`;
    }
  }, [shouldUserAuthenticate, shouldRedirectToAccount, fetch]);

  const updateProfile = useCallback(() => {
    fetch( "/api/profile").then((data) => {
      setProfile(data);
    }, (error) => {
      setProfileError(error);
      setProfile(false);
    });
  }, [setProfile, setProfileError, profile, fetch]);

  useEffect(() => {
    if (profile == null && !waitingForReload)
      updateProfile();
  }, [profile, updateProfile, waitingForReload]);

  const context = useMemo(
    () => ({
      apiKey,
      shop,
      embedded,
      fetch,
      fetchSessionToken,
      profile,
      profileError,
      updateProfile
    }),
    [apiKey, shop, fetch, fetchSessionToken, profile, profileError]
  );
  
  if (shouldUserAuthenticate || shouldRedirectToAccount)
    return null;

  return  <Auth.Provider value={context}>{children}</Auth.Provider>;
};

export const useIsEmbedded = () => useContext(Auth).embedded;
export const useShop = () => useContext(Auth).shop;
export const useFetch = () => useContext(Auth).fetch;
export const useFetchSessionToken = () => useContext(Auth).fetchSessionToken;
export const useApiKey = () => useContext(Auth).apiKey;
export const useProfile = () => {
  const auth = useContext(Auth);
  return [auth.profile, auth.profileError, auth.updateProfile];
};

export default {
  useFetch,
  useFetchSessionToken,
  useProfile,
};

