
import "./spot.js"
import "./spot-control-panel-sdk.js"
import { Auth } from "admin-frontend";
import { useMemo, useEffect, useCallback, useState, createContext, useContext } from "react";
import { ResourcePicker } from "./ResourcePicker";
import { useHistory, useLocation } from "react-router-dom";

export const SpotContext = createContext(null);
export const SpotCPSDKContext = createContext(null);

export const useSpot = () => { return useContext(SpotContext); };
export const useSpotCPSDK = () => { return useContext(SpotCPSDKContext); };
export const useSpotAPI = () => {
	const spot = useSpot();
	return spot && spot.spotAPI;
}


export function SpotState({ children, init, allowNoState, id, externalSearch = false }) {
  const [spotDOM, setSpotDOM] = useState(null);
  const [spotDefault, setSpotDefault] = useState(null);
  const [spotCPSDK, setSpotCPSDK] = useState(null);
  const [refresh, setRefresh] = useState(0);
  const [picker, setPicker] = useState(null);
  const history = useHistory();
  const location = useLocation();

	
  const [profile] = Auth.useProfile();
	const sessionTokenFetch = Auth.useFetchSessionToken();

  const spotAPI = useMemo(() => {
		if (profile) {
			const spotAPI = new window.SpotAPI();
			const hostname = profile && profile.shop.shop_origin;
			spotAPI.defaultQuery(spotAPI.se().category('spot-internal').hostname(hostname));
			spotAPI._jsStyle = false;
			// Queue up requests until we get a token.
			spotAPI.oldSpotAPIAjax = spotAPI.ajax;
			spotAPI.spotRequests = [];
			spotAPI.id = id;
			spotAPI.ajax = function() {
				var deferred = spotAPI.Deferred();
				spotAPI.spotRequests.push([deferred, arguments]);
				return deferred;
			};
			return spotAPI;
		}
		return null;
	}, [profile]);

	if (spotAPI) {
		if (!window.spots)
			window.spots = [];
		if (window.spots.filter((s) => s == spotAPI).length == 0)
			window.spots.push(spotAPI);
	}

  const updateToken = useCallback(() => {
		sessionTokenFetch().then((sessionToken) => {
			if (sessionToken && spotAPI) {
				window.sessionToken = sessionToken;
				if (externalSearch && profile.shop && profile.shop.clusters.length > 0 && profile.shop.clusters[0] != "localhost") {
					spotAPI.headers({ });
					spotAPI.clusters(profile.shop.clusters);
				/*} else if (externalSearch && profile.shop && profile.shop.clusters[0] == "localhost") {
					spotAPI.headers({ });
					spotAPI.clusters(["spot-cluster-ca-0.moddapps.com"]);*/
				} else {
					spotAPI.clusters(["/api/search/engine"]);
					spotAPI.headers({ Authorization: "Bearer " + sessionToken });
				}
				// Once we have a token, release requests.
				if (spotAPI.spotRequests) {
					spotAPI.ajax = spotAPI.oldSpotAPIAjax;
					spotAPI.spotRequests.forEach((r) => {
						spotAPI.ajax.apply(spotAPI, r[1]).done((...args) => {
							r[0].resolve(...args);
						}).fail((...args) => {
							r[0].reject(...args);
						});
					});
					spotAPI.spotRequests = null;
				}
			}
		});
  }, [sessionTokenFetch, externalSearch, profile, spotAPI]);

  useEffect(() => {
		if (spotAPI) {
			updateToken();
			if (spotAPI.spotRefreshInterval)
				clearInterval(spotAPI.spotRefreshInterval);
			spotAPI.spotRefreshInterval = setInterval(() => {
				updateToken();
			}, 10000);
			return () => {
				if (spotAPI.spotRefreshInterval)
					clearInterval(spotAPI.spotRefreshInterval);
			}
		}
  }, [updateToken, spotAPI, externalSearch]);

  const spot = useMemo(() => {
		return { spotAPI: spotAPI, spotDOM: spotDOM, spotDefault: spotDefault };
  }, [spotAPI, spotDOM, spotDefault]);

  useEffect(() => {
    if (spotAPI && !spotDOM && !spotDefault) {
      const spotDOM = new window.SpotDOM(spotAPI, { history: history });
      const spotDefault = new window.SpotDefault(spotDOM);
      spotDOM.countBehavior("exact").optionBehavior("exact");
      spotDOM.failBehavior(null);
      spotDOM.cpSDK = new window.SpotCPSDK(spotAPI, spotDOM, spotDefault, {
				picker: ((props) => {
					var deferred = spotAPI.Deferred()
					setPicker({ ...props, 
						open: true,
						beforeQueryRun: props.queryTransform,
						onCancel: () => {
							setPicker(null);
							deferred.reject();
						}, 
						onSelection: ({ selection, ...others }) => {
							deferred.resolve(selection);
							setPicker(null);
						} 
					});
					return deferred;
				}),
				refresh: () => {
					setSpotCPSDK([spotDOM.cpSDK, new Date()]);
				}
			});
      if (init)
        init(spotAPI, spotDOM, spotDefault);
      setSpotDefault(spotDefault);
      setSpotDOM(spotDOM); 
      setSpotCPSDK([spotDOM.cpSDK, null]);
    }
  }, [spotAPI]);

  useEffect(() => {
		if (spotDOM) {
			if (history)
				spotDOM.history = { pushState: (state, title, path) => { 
					const url = new URL(path);
					history.push(url.pathname + url.search); 
					window.history.pushState(state, title, path);
				} };
			if (location)
				spotDOM.location = { ...location, protocol: window.location.protocol, host: window.location.hostname, href: window.location.protocol + "//" + window.location.hostname + location.pathname + location.search };
		}
  }, [history, location, spotDOM])

  useEffect(() => {
		if (init && spotAPI && spotDOM && spotDefault)
			init(spotAPI, spotDOM, spotDefault);
  }, [init, spotDOM, spotDefault, spotAPI]);

	if (!spotDOM && allowNoState)
		return <div>{children}</div>;
	if (!spotDOM)
		return <div></div>;
	return <SpotContext.Provider value={spot}>
		<SpotCPSDKContext.Provider value={spotCPSDK}>
			{picker && <ResourcePicker {...picker}/>}
			{children}
		</SpotCPSDKContext.Provider>
	</SpotContext.Provider>;
}
