import React, {useEffect, useState, useCallback} from 'react';
import {
  TextField,
  Select,
  Stack,
} from 'admin-frontend';
import { useSpotAPI } from "./API";
import { productFields } from "./Fields";


export function ValueSelector({ field, collection, value, multiple = false, onChange}){
    const spotAPI = useSpotAPI();
    const [loadedField, setLoadedField] = useState(null);
    const [valueList, setValueList] = useState([]);
    const [valuesLoading, setValuesLoading] = useState(false);
    const [selectedValues, setSelectedValues] = useState([]);
    const [searchString, setSearchString] = useState("");
    const [noResults, setNoResults] = useState(false);

    var collectionHandle = collection && collection.handle;

    
    const performSearch = useCallback((search) => {
      const fieldName = field[0];
      const productField = productFields.filter((f) => f.handle == fieldName)[0];
      if (!productField || productField.type != "numeric") {
        var hash = {};
        function wrapValue(value) {
          if (search != null && search != '')
            return {[value]:[{"value":{"*":  "*" + search + "*"}}]}
          return value;
        }
    
        if (
          fieldName === "product-metafield" ||
          fieldName === "variant-metafield"
        ) {
          hash[fieldName] = {};
          hash[fieldName][field[1]] = wrapValue(field[2]);
          collectionHandle = "";
        } else if (fieldName === "option") {
          hash[fieldName] = wrapValue(field[1]);
        } else if (
          fieldName === "product-custom-field" ||
          fieldName === "variant-custom-field"
        ) {
          collectionHandle = "";
          hash[fieldName] = wrapValue(field[1]);
        } else 
          hash = wrapValue(fieldName);

        setValuesLoading(true);
        spotAPI.s().options([hash], true, "exists").rows(0).collection(collectionHandle).e().done((products, count, options) => {
          var values;
          values = options["options"][0][fieldName];
          if (fieldName === "product-metafield" || fieldName === "variant-metafield")
              values = values[field[1]][field[2]];
          else if (fieldName === "option")
              values = values[field[1]];
          else if (fieldName === "product-custom-field" || fieldName === "variant-custom-field")
              values = values[field[1]];
          
          let retrievedHash = {};
          if (values && Object.keys(values).length > 0)
              retrievedHash = { ...retrievedHash, ...values };
          
          let retrievedList = [...Object.keys(retrievedHash).map((e) => { return { label: e, value: e }; })];
          if (retrievedList){
              setValueList(retrievedList.filter(item => item.value !== null && item.value !== ""));
              setNoResults(false);
          } else {
              setValueList([]);
              setSelectedValues([]);
              setNoResults(true);
          }
          setValuesLoading(false);
        }).fail(() => { 
          setValueList([]); 
          setSelectedValues([]); 
          setNoResults(true);
        });
      } else {
        setValueList([]); 
        setSelectedValues([]); 
        setNoResults(true);
      }
    }, [collection, field, spotAPI]);

    useEffect(() => {
        if (!loadedField || loadedField !== field){
            performSearch("")
            setLoadedField(field)
            setSearchString("")
        }
      }, [field, loadedField, performSearch, searchString]);
    
    return valueList && (
      <Stack vertical spacing="loose" fill={true} wrap={false}>
        {!noResults ? (
          <Select
            fullWidth
            onChange={(values) => {
              setSelectedValues(values);
              if (multiple == "comma")
                onChange(values.join(","));
              else
                onChange(values);
            }}
            options={valueList.map((v) => {
              return { ...v, disabled: valuesLoading };
            })}
            value={value}
            selected={value == "" ? [] : (value && multiple == "comma" && !Array.isArray(value) ? value.split(/,/) : value)}
            multiple={multiple}
            searchable
            searchValue
            onSearch={(search) => {
                if(search !== searchString){
                    setSearchString(search);
                    performSearch(search)
                }
            }}
          />
        ):
        <TextField
          fullWidth
          onChange={(values) => {
            setSelectedValues(values);
            if (multiple == "comma")
              onChange(values.join(","));
            else
              onChange(values);
          }}
          options={valueList.map((v) => {
            return { ...v, disabled: valuesLoading };
          })}
          value={value}
          allowMultiple
          searchable
          searchValue
        />
      }
      </Stack>
    );
    
}
