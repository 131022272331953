import React from "react";

import { Banner } from "./Polaris";
import { useProfile, useFetch } from "../contexts/Auth";
import { useRedirect } from "../contexts/Route";

function AuthorizationBanner({ scopes, style, message, title, type, redirect_uri }) {
  // For snappy dismissal.
  const [profile] = useProfile();
  const redirect = useRedirect();
  const authFetch = useFetch();
  const extantScopes = Object.fromEntries(
    profile.shop.scope.split(/\s*,\s*/).map((e) => {
      const groups = /^(read|write)_(\w+)$/.exec(e);
      return groups ? [groups[2], groups[1]] : null;
    }).filter((s) => s)
  );
  const missingScopes = scopes.filter((s) => {
    const groups = /^(read|write)_(\w+)$/.exec(s);
    return groups && (!extantScopes[groups[2]] || (extantScopes[groups[2]] === "read" && groups[1] === "write"));
  });
  return (
    missingScopes.length > 0 && (
      <div style={style}>
        <Banner
          title={title || "New Scopes Needed"}
          status={type || "critical"}
          action={{
            content: "Authorize",
            onAction: () => {
              authFetch("/api/profile/scopes/add", { json: { scopes: missingScopes, redirect_uri: redirect_uri || window.location.pathname } })
                .then(() => authFetch("/api/login", { query: { shop: profile.shop.shop_origin } }))
                .then((r) => redirect(r.url));
            },
          }}
        >
          {typeof message === "function"
            ? message(missingScopes)
            : message ||
              `We're missing the necessary scopes we require for this page to function properly. You're currently missing the following: ${missingScopes.join(
                ","
              )}. Click below to authorize.`}
        </Banner>
      </div>
    )
  );
}

export default AuthorizationBanner;
