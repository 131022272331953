/* eslint-disable no-console */
import React, { useCallback, useState, useContext, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Auth, FormLayout, InlineError, Heading, Spinner, Link, Banner, TextField, ButtonGroup, Button, Form, Layout, TextContainer, Card, useFetch, SubscriptionHistoryTable, PlanFeatureMatrix, SubscriptionContext, useRedirect, PermissionGuard, useCreateToast, Stack } from "admin-frontend";
import { Page } from "../components/Page";

import { useSpotFetch } from "../useSpotFetch";

export function Subscriptions({ termsOfService, termsOfServiceURL }) {
  const authFetch = useSpotFetch();
  const [profile, profileError, invalidateProfile] = Auth.useProfile();
  const redirect = useRedirect();
  const [plans, setPlans] = useState(null);
  const [subscriptions, setSubscriptions] = useState(null);
  
  useEffect(() => {
    if (profile) {
      authFetch('/api/subscriptions', { }).then((r) => {
        setPlans(r.plans);
        setSubscriptions(r.active_subscriptions);
      })
    }
  }, [authFetch]);

  const [discountCode, setDiscountCode] = useState("");
  const [discountError, setDiscountError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  
  return (
    <Page permission="account" isFullyLoading={!subscriptions || !plans} fullWidth title="Account">
      {profile?.shop?.is_affiliate && !profile?.shop?.ready && (<Banner status="success" title="Affiliate Plans Available">
        <TextContainer>
          <p>As you are on an <b>affiliate plan</b> with Shopify, you qualify for a free development license of Spot. Contact <a onClick={() => {
            window.open(
              `https://static.zdassets.com/web_widget/latest/liveChat.html?v=10#key=moddapps.zendesk.com&settings=${
                btoa(JSON.stringify(window.zESettings || { webWidget: { chat: {}, contactForm: {} } }))
              }&locale=en-ca&title=Web%20Widget%20Live%20Chat`,
              'ChatWindow',
              'width=400,height=675'
            );
          }} href='#'>spot@moddapps.com</a> for details!</p>
        </TextContainer>
      </Banner>)}
      <Stack vertical>
        <Stack wrap={false}>
          {plans && plans.map((p) => {
            const associatedSubscription = subscriptions && subscriptions.filter((s) => s.plan_id == p.id)[0];
            return <Stack.Item key={"plan-" + p.id} fill><Card title={p.name}>
              <Card.Section>
                <Stack vertical>
                  <Heading>{p.monthly_cost} USD</Heading>
                  <Heading>{p.trial_days} Trial Days</Heading>
                  <Button fullWidth primary disabled={associatedSubscription || isLoading} loading={isLoading == p.id} onClick={!associatedSubscription && (() => { 
                    setIsLoading(p.id);
                    authFetch("/api/subscriptions", { json: { subscription: { plan_id: p.id } } }).then((r) => {
                      if (r.redirect_to)
                        redirect(r.redirect_to);
                      else
                        invalidateProfile();
                      }).finally(() => { 
                        setIsLoading(false);
                      });
                  })}>{associatedSubscription ? "Current Plan" : (!subscriptions || subscriptions.length == 0 ? "Sign Up" : "Upgrade")}</Button>
                </Stack>
              </Card.Section>
              <Card.Section>
                <Stack vertical>
                  {Object.keys(p.features).sort((a,b) => p.features[a].display.rank - p.features[b].display.rank).map((f, idx) => {
                    const feature = p.features[f];
                    if (feature.value === true || feature.value === false) {
                      return (<div key={`feature-${idx}`}>
                        {!feature.value ? <s>{feature.display.key}</s> : feature.display.key}
                      </div>);
                    } else {
                      return (<div key={`feature-${idx}`}>
                        {feature.display.value} {feature.display.key}
                      </div>);
                    }
                  })}
                </Stack>
              </Card.Section>
            </Card></Stack.Item>})}
        </Stack>
        <Card title="Discount Code" sectioned>
          <Form onSubmit={() => {
            if (!discountCode) {
              setDiscountError("Please enter a discount code.");
            } else if (discountCode.length < 10) {
              setDiscountError("Discount codes must be at least 10 characters long.");
            } else {
              setDiscountError(null);
              setIsLoading(true);
              authFetch("/api/subscriptions", { json: { subscription: { discount_code: discountCode } } }).then((r) => {
                if (r.message)
                  setDiscountError(r.message);
                else if (r.redirect_to)
                  redirect(r.redirect_to);
                else
                  invalidateProfile();
              }).finally(() => { 
                setIsLoading(false); 
                setDiscountCode("");
              });
            }
          }}>
            <ButtonGroup>
              <TextField disabled={isLoading} value={discountCode} onChange={setDiscountCode} error={discountError} />
              <Button loading={isLoading} disabled={isLoading} submit>Submit</Button>
            </ButtonGroup>
          </Form>
        </Card>
        <SubscriptionHistoryTable subscriptions={subscriptions}/>
        {termsOfServiceURL && (<Banner title="Terms of Service">
          <TextContainer>
            <p>By signing up for a plan with Spot, you agree to abide by the Spot <Link url={termsOfServiceURL}>terms of service</Link>.</p>
          </TextContainer>
        </Banner>)}
      </Stack>
    </Page>
  );
};
