window.SpotCPSDK = function(spotAPI, spotDOM, spotDefault, options) {
  this.spotAPI = spotAPI;
  this.spotDOM = spotDOM;
  this.spotDefault = spotDefault;
  this.userStorage = {};
  this.shopStorage = {};
  this.getUserStorage = function(key) { 
    return this.userStorage[key] ||= new Promise((resolve, reject) => { 
      window.controlPanelFetch(`/api/user/transient/${key}`).then((r) => { resolve(r.value); }); 
    });
  };
  this.setUserStorage = function(key, value) { 
    return new Promise((resolve, reject) => { 
      this.userStorage[key] = new Promise((resolve, reject) => { resolve(value); });
      window.controlPanelFetch(`/api/user/transient/${key}`, { json: { value: value } }).then((r) => resolve(r.value)); 
    }); 
  };
  this.getShopStorage = function(key) { 
    return this.shopStorage[key] ||= new Promise((resolve, reject) => { 
      window.controlPanelFetch(`/api/shop/transient/${key}`).then((r) => { resolve(r.value); }); 
    }); 
  };
  this.setShopStorage = function(key, value) { 
    this.shopStorage[key] = new Promise((resolve, reject) => { resolve(value); });
    return new Promise((resolve, reject) => { 
      window.controlPanelFetch(`/api/shop/transient/${key}`, { json: { value: value } }).then((r) => resolve(r.value)); 
    }); 
  };
  this.picker = options.picker;
  this.refresh = options.refresh;
  this.updateMetafields = function(metafields) {
    return window.controlPanelFetch('/api/storefront/metafields', { json: { metafields: metafields } });
  };
};
